const comJs = {
	isCom: true,
	/**
	 * 应用名
	 */
	name: '船运',
	/**
	 * 版本
	 */
	version: '1.0.0',
	// 开发跳转路径
	devHref: 'http://localhost:8080/',
	// devHref: 'https://31i7939129.picp.vip/',
	// 生产跳转路径
	prodHref: 'https://www.hy151.com/',
	// prodHref: 'https://www.hy151.com/test/', 
	/**
	 * 开发环境host
	 */
	// devHost: 'https://ship.hy151.cn/front', // .com
	devHost: 'http://localhost:8222/front',
	/**115 8222 121 120  8222
	 * 测试环境转发规则
	 */
	devApi: '',
	/**
	 * 生产环境host
	 */
	prodHost: 'https://ship.hy151.cn/front',
	/**
	 * 生产环境转发规则
	 */
	prodApi: '',
	/**
	 * 请求头名称
	 */
	tokenHeadName: 'Token',
	visitHeadName: 'Visit',
	/**
	 * 请求头类型
	 */
	// tokenTypeName: 'Bearer ',

	/**
	 * 是否开启前端oss直传
	 */
	ossEnable: false,

	/**
	 * 微信公众号appid,填写即代表开启公众号登录
	 */
	wxH5AppId: 'wxa25920bb0887d8c6',

	/**
	 * 微信公众号授权方式snsapi_base或snsapi_userinfo
	 */
	wxH5scope: 'snsapi_userinfo'
}
const cnJs = {
	isCom: false,
	/**
	 * 应用名
	 */
	name: '船运',
	/**
	 * 版本
	 */
	version: '1.0.0',
	// 开发跳转路径
	devHref: 'http://localhost:8080/',
	// devHref: 'https://31i7939129.picp.vip/',
	// 生产跳转路径
	prodHref: 'https://www.hy151.cn/',
	// prodHref: 'https://www.hy151.cn/test/', 
	/**
	 * 开发环境host
	 */
	// devHost: 'https://front.hy151.cn', // .cn
	// devHost: 'http://77n8ah.natappfree.cc/front',// .cn
	devHost: 'http://localhost:8222/front',
	/**115 8222 121 120  8222  
	 * 测试环境转发规则
	 */
	devApi: '',
	/**
	 * 生产环境host
	 */
	prodHost: 'https://front.hy151.cn',
	/**
	 * 生产环境转发规则
	 */
	prodApi: '',
	/**
	 * 请求头名称
	 */
	tokenHeadName: 'Token',
	visitHeadName: 'Visit',
	/**
	 * 请求头类型
	 */
	// tokenTypeName: 'Bearer ',

	/**
	 * 是否开启前端oss直传
	 */
	ossEnable: false,

	/**
	 * 微信公众号appid,填写即代表开启公众号登录
	 */
	wxH5AppId: 'wxa25920bb0887d8c6',

	/**
	 * 微信公众号授权方式snsapi_base或snsapi_userinfo
	 */
	wxH5scope: 'snsapi_userinfo'
}



/**
 * 参数配置文件
 */
module.exports = comJs
// module.exports = cnJs